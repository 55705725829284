.first{
    margin:5px 10px;
    padding: 0 20px;
 
  
    cursor: pointer;
    overflow: hidden;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);   
}

.first :hover {
    transform: scale(1.05);
}

.indiastrustedwebsitedevelopment {
    color: rgb(240, 28, 151);
}

.button {
    background-color:rgb(240, 28, 151);
    border: none;
    color: white;
    padding: 10px 25px;
  
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 25px;
  }

 

  .buttoncoding {
    background-color: rgb(240, 28, 151); /* Green */
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 14px;
  }
  

  .button2 {
    background-color: white; 
    color: rgb(35, 36, 37); 
    border: 2px solid rgb(240, 28, 151);
  }
  
  .button2:hover {
    background-color: rgb(240, 28, 151);
    color: white;
  
  }


.backgroundc{
  background-color: rgb(240, 28, 151);
}

.mrindiancoderlive{
  color: white;
}
.mrindiancoder_global{
  background-color: light;
}

.buttonbookfranchise{
  background-color: rgb(240, 28, 151); /* Green */
  border: none;
  color: white;
  padding: 9px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 14px;;

}

.texts{
  text-align: justify;
  text-justify: inter-word;
}



* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.hoverimagecoder {
  position: relative;

}

/* Make the image to responsive */
.image5 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.bestwebsitedeveloperwestbengal {
  position: absolute;
  bottom: 0;
  background: rgb(162, 194, 240);
  background: rgba(23, 23, 23, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 25px;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimagecoder:hover .bestwebsitedeveloperwestbengal {
  opacity: 1;
}

.backradius img{
  border-radius: 11px;
}