.umastyle{
    
    background-color: rgb(240, 28, 151);
    padding: 0%;
    color: white;
    text-decoration-color:  rgb(240, 28, 151);
}


.codingacademy{
    
    background-color: rgb(4, 81, 117);
    padding: 0%;
    color: white;
    text-decoration-color:  rgb(240, 28, 151);
}

